@import "../../../variables";
@import "../../../breakpoints.scss";

@mixin container {
  min-height: 500px;
  padding-bottom: 75px;
}

@mixin defaultPadding {
  padding: 50px 30px 0 30px;

  @media screen and (min-width: $breakpoint-md) {
    padding: 75px 75px 0 75px;
  }
}

.defaultContainer {
  @include container;

  background-color: $white;
  color: $dark;
}

.alternateContainer {
  @include container;

  background-color: $dark;
  color: $white;
}

.sectionHeading {
  @include defaultPadding;
}

.sectionTitle {
  font-weight: 700;
  font-size: 50px;
}

.sectionSubtitle {
  font-weight: 300;
  font-size: 25px;
}
