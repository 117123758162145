@import "../../../breakpoints.scss";

.container {
  margin: 50px 30px 0 30px;

  @media screen and (min-width: $breakpoint-md) {
    margin: 50px 75px 0 75px;
  }
}

.projectTitle {
  font-weight: 700;
  font-size: 50px;
}

.projectSubtitle {
  font-weight: 300;
  font-size: 25px;
}
