@import "../../../breakpoints.scss";

.carouselContainer {
  margin: 50px 30px 0 30px;

  @media screen and (min-width: $breakpoint-md) {
    margin: 50px 75px 0 75px;
  }
}

.carouselName {
  font-size: 30px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 70px;
  text-decoration: underline;
  text-underline-offset: 20px;
}

.imageColumn {
  margin-bottom: 50px;
}
