@import "../../../breakpoints.scss";

.cardContainer {
  margin: 50px 30px 0 30px;

  @media screen and (min-width: $breakpoint-md) {
    margin: 50px 75px 0 75px;
  }
}

.cardHeader {
  font-weight: bold;
}

.fontName {
  font-size: 50px;

  @media screen and (min-width: $breakpoint-lg) {
    font-size: 70px;
  }
}

.typingText {
  font-size: 20px;

  @media screen and (min-width: $breakpoint-lg) {
    font-size: 30px;
  }
}

.fontDescription {
  margin-top: 50px;
}
