@import "../../../../breakpoints.scss";

.imageContainer {
  position: relative;
  text-align: center;
  height: 350px;

  @media screen and (min-width: $breakpoint-lg) {
    height: 450px;
  }
}

.image {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.cardHeader {
  font-weight: 700;
  text-align: center;
  border: 1px;
}

.imageCard {
  border: none;
}
