@import "../../../breakpoints.scss";

.cardContainer {
  margin: 50px 30px 50px 30px;

  @media screen and (min-width: $breakpoint-lg) {
    margin: 50px 75px 50px 75px;
  }
}

.cardHeader {
  font-weight: bold;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.textContainer {
  padding: 50px;
}

.cardTitle {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 20px;
}

.cardDescription {
  @media screen and (min-width: $breakpoint-lg) {
    padding-right: 30px;
  }
}
