@import "../../../breakpoints.scss";

.pageContainer {
  margin: 0;
}

.landingImageCard {
  text-align: center;
  height: 300px;
  width: 100%;
  position: relative;

  @media screen and (min-width: $breakpoint-lg) {
    height: 95vh;
  }
}

.landingImage {
  display: block;
  margin-left: auto;
  margin-right: auto;

  max-height: 80%;
  max-width: 80%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.landingCard {
  padding: 5%;
  border-radius: 0 !important;

  @media screen and (min-width: $breakpoint-lg) {
    min-height: 95vh;
  }
}

.projectOverview {
  margin-top: 7%;
  font-weight: 200;
  font-size: 20px;

  @media screen and (min-width: $breakpoint-lg) {
    font-size: 35px;
  }
}

.projectTitle {
  font-size: 50px;

  @media screen and (min-width: $breakpoint-lg) {
    font-size: 100px;
  }
}

.pageSectionButtons {
  margin-top: 5%;
}

.pageSectionButton {
  width: 100%;
  margin-top: 5%;
}
