@import "../../../breakpoints.scss";

.imageContainer {
  text-align: center;
  min-height: 300px;
  width: 100%;
  position: relative;
}

.image {
  display: block;
  margin-left: auto;
  margin-right: auto;

  max-height: 90%;
  max-width: 90%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.textContainer {
  padding: 20px;

  @media screen and (min-width: $breakpoint-lg) {
    padding: 75px;
  }
}

.description {
  margin-top: 30px;
}
