@import "../../../breakpoints.scss";

.droplet {
  margin: 0 auto;
  margin-top: 7%;
  width: 100px;
  height: 100px;
  border-radius: 100%;

  @media screen and (min-width: $breakpoint-md) {
    width: 150px;
    height: 150px;
  }
}

.colorDetails {
  padding-top: 10%;
  text-align: center;
}

.colorName {
  font-weight: 500;
}

.colorCode {
  font-weight: 300;
}

.colorsContainer {
  margin: 50px 30px 0 30px;

  @media screen and (min-width: $breakpoint-md) {
    margin: 50px 75px 0 75px;
  }
}

.cardHeader {
  font-weight: 700;
}
