@import "../../breakpoints.scss";

.pageHeader {
  margin: 50px 30px 0 30px;

  @media screen and (min-width: $breakpoint-md) {
    margin: 50px 75px 0 75px;
  }

  text-align: center;
  margin-bottom: 5%;
}
