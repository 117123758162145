@import "../../breakpoints.scss";

.constantContainer {
  margin: 50px 30px 0 30px;

  @media screen and (min-width: $breakpoint-md) {
    margin: 50px 75px 0 75px;
  }
}

.pageHeader {
  text-align: center;
  margin-bottom: 50px;
}

.portfolioContent {
  margin-top: 90px;
}
