@import "../../../breakpoints.scss";

.cardContainer {
  margin: 50px 30px 0 30px;

  @media screen and (min-width: $breakpoint-md) {
    margin: 50px 75px 0 75px;
  }
}

.cardHeader {
  font-weight: 700;
}
