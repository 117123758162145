@import "../../breakpoints.scss";

.container {
  padding-top: 18%;
  padding-left: 5%;
  padding-right: 5%;

  @media screen and (min-width: $breakpoint-md) {
    padding-top: 18%;
    padding-left: 3%;
    padding-right: 3%;
  }
}

.landingTextContainer {
  margin: 20% 0;

  @media screen and (max-width: $breakpoint-md) {
    margin-left: 5%;
  }
}

.splashImage {
  transform: scale(1);

  @media all and (min-width: $breakpoint-md) {
    transform: scale(1.3);
  }
}

.headerText {
  @media screen and (max-width: $breakpoint-md) {
    font-size: 28px;
  }
}
